.work-container {
  width: 100%;
  min-height: 91.7vh;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
}

h1 {
  color: #1f1f1f;
}

.job-cards-container {
  width: 100%;
  min-height: 400px;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.job-card-item {
  min-height: 330px;
  height: 80%;
  min-width: 30%;
  width: auto;
  margin: 40px;
  text-align: center;
  border-radius: 25px;
  box-shadow: 0 0 8px #888888;
  border-top: 5px solid #50cbaf;
  display: flex;
  flex-direction: column;
}

.job-card-item > h1 {
  margin: 50px;
  color: #292929;
}

.job-card-item > p {
  font-size: 18px;
  color: #3b3b3b;
  margin-left: 50px;
  margin-right: 50px;
}

.job-header-container {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  text-align: center;
}

.job-body-container {
  width: 100%;
  height: 70%;
  text-align: left;
}

.job-body-container > ul {
  font-size: 18px;
  font-family: sans-serif;
  color: #1b1b1b;
  margin: 20px;
  margin-left: 30px;
}

.job-header-container > img {
  height: 100px;
  width: 100px;
  border-radius: 25px;
  margin-left: 10px;
}

.job-header-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.job-header-text-container > h1 {
  margin-bottom: 0;
}

.job-header-text-container > p {
  line-height: 18px;
}

.project-cards-container {
  width: 100%;
  min-height: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-card-item {
  width: 80%;
  min-height: 100px;
  height: auto;
  border-radius: 25px;
  box-shadow: 0 0 8px #888888;
  border-top: 5px solid #50cbaf;
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.project-card-text {
  width: 70%;
  height: 100%;
  text-align: left;
  margin: 30px;
}

.project-card-text > p {
  font-size: 22px;
  font-family: sans-serif;
  line-height: 35px;
  margin-top: 70px;
}

.project-card-text > p > a {
  text-decoration: none;
  color: #1b1b1b;
}

.project-card-media {
  height: 380px;
  width: 380px;
  margin: 30px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 15px;
}

.project-card-media > video {
  height: 100%;
  width: 100%;
}

.project-card-media > img {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1800px) {
  .job-header-text-container > h1 {
    font-size: 24px;
  }

  .job-body-container > ul {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1600px) {
  .job-card-item {
    width: 70%;
    min-height: 200px;
    height: auto;
  }

  .job-cards-container {
    flex-direction: column;
    height: auto;
  }

  .job-header-container > img {
    margin-left: 25px;
  }

  .work-container {
    height: auto;
  }

  .job-header-container {
    height: 30%;
  }

  .job-body-container {
    height: 70%;
  }

  .job-body-container > ul {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1300px) {
  .project-card-item {
    flex-direction: column;
    align-items: center;
  }

  .project-card-text {
    text-align: center;
  }
}

@media only screen and (max-width: 1050px) {
  .project-card-item > p {
    font-size: 20px;
  }

  .project-card-text {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .job-card-item {
    width: 80%;
  }

  .project-card-item {
    width: 80%;
  }

  .project-card-text > p {
    font-size: 18px;
    margin-top: 50px;
  }

  .project-card-text {
    width: 95%;
  }
}

@media only screen and (max-width: 500px) {
  .project-card-media {
    height: auto;
    width: auto;
  }

  .job-header-container {
    flex-direction: column;
    align-items: center;
  }

  .job-header-container > img {
    margin: 0;
  }

  .job-card-item {
    width: 85%;
  }

  .project-card-item {
    width: 85%;
  }

  .project-card-text > p {
    font-size: 16px;
  }
}
