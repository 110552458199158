.main-container {
  padding: 4rem;
  background-image: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  display: flex;
  flex-direction: row;
}

.about-container {
  display: flex;
  flex-direction: column;
  background-color: #082d3b;
  border-radius: 30px;
  width: 40%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.skills-container {
  display: flex;
  flex-direction: column;
  background-color: #082d3b;
  border-radius: 30px;
  width: 40%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  min-height: 450px;
}

.about-container > h1,
.skills-container > h1 {
  color: #fff;
  margin-top: 20px;
}

.about-container > p {
  color: #fff;
  margin: 20px;
  margin-top: 50px;
  line-height: 1.5;
  font-size: 22px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

@media only screen and (max-width: 1200px) {
  .main-container {
    flex-direction: column;
    padding: 20px;
  }
  .about-container {
    width: 80%;
    margin-bottom: 20px;
  }

  .skills-container {
    width: 80%;
  }
}

@media only screen and (max-width: 800px) {
  .about-container {
    width: 90%;
  }

  .skills-container {
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .about-container {
    width: 95%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .skills-container {
    width: 95%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .about-container > p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .about-container {
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .about-container > p {
    margin-left: 10px;
    margin-right: 10px;
  }

  .skills-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
