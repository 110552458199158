.document-wrapper {
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.document-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
  float: left;
  text-align: center;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  box-shadow: 0 0 15px #888888;
  border-top: 5px solid #50cbaf;
}

.document-pdf {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border: none;
  display: block;
  width: 95%;
  height: 680px;
  margin-bottom: 50px;
}

.document-mobile {
  margin: 20px;
}

.document-mobile > a {
  text-decoration: underline;
  color: #1b1b1b;
}

h1 {
  color: #181818;
  font-size: 50;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1300px) {
  .document-wrapper {
    flex-direction: column;
  }

  .document-container {
    width: 95%;
  }
}
