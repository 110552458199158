.hero-container > video {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: 100vh;
  height: 100vh;
  position: fixed;
  z-index: -10;
  top: 0;
  left: 0;
}

.hero-container {
  min-height: 60vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  overflow: hidden;
}

.text-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
}

.image-container {
  width: 50%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
}

.image-container > img {
  min-height: 300px;
  height: 300px;
  min-width: 240px;
  width: 240px;
  border: 5px solid #fff;
  border-radius: 35px;
}

.text-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: 0px;
  text-align: center;
}

.text-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: center;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 1300px) {
  .text-container > h1 {
    font-size: 50px;
  }

  .text-container > p {
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) {
  .hero-container {
    flex-direction: column;
  }

  .text-container {
    height: 50%;
    width: 80%;
    margin-top: 50px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
