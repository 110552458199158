* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.resume {
  color: #000000;
  font-size: 100px;
  min-height: 100%;
}

.experience {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000000;
  font-size: 100px;
}

.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
  font-size: 100px;
}
