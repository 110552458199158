.contact-main-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 91.7vh;
  width: auto;
  height: auto;
  align-items: center;
}

.contact-text-container {
  width: 80%;
  min-height: 200px;
  height: 30%;
  text-align: center;
  font-family: Arial, sans-serif;
}

.contact-text-container > h1 {
  margin-top: 100px;
  font-size: 55px;
  color: #181818;
}

.contact-cards-container {
  width: 80%;
  min-height: 600px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contact-card-item {
  min-height: 400px;
  height: 60%;
  min-width: 400px;
  width: 30%;
  margin: 40px;
  text-align: center;
  border-radius: 25px;
  box-shadow: 0 0 8px #888888;
  border-top: 5px solid #50cbaf;
  justify-content: center;
}

.contact-card-item > h1 {
  margin: 50px;
  color: #292929;
}

.contact-card-item > p {
  font-size: 18px;
  color: #3b3b3b;
  margin-left: 50px;
  margin-right: 50px;
}

.email-button,
.phone-button {
  font-size: 20px;
  height: 50px;
  width: 190px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: #fff;
  color: #50cbaf;
  border: 2px solid #50cbaf;
  border-radius: 20px;
  box-shadow: 0 0 8px #888888;
  outline: none;
}

.email-button:hover,
.phone-button:hover {
  background-color: #50cbaf;
  color: #fff;
}

.phone-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phone-button > i {
  font-size: 24px;
  margin: 0 auto;
}

.phone-button > a {
  font-size: 18px;
  color: #50cbaf;
  margin: 0 auto;
}

.phone-button:hover > a {
  color: #fff;
}

@media only screen and (max-width: 500px) {
  .contact-card-item {
    min-width: 300px;
    width: auto;
  }
}
