.cards-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 95%;
  height: 80%;
  margin: 0 auto;
  overflow: auto;
  border-radius: 20px;
}

.card-item {
  width: 97%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 20px;
}

.img-container {
  height: 100px;
  width: 100px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-container > img {
  display: block;
  height: 95%;
  width: 95%;
  margin: auto;
}

.txt-container {
  width: 85%;
  height: auto;
  text-align: left;
  padding-right: 50px;
}

.txt-container > h1 {
  font-size: 14;
  color: black;
  padding-left: 30px;
}

.txt-container > p {
  font-size: 12;
  color: black;
  padding-left: 30px;
  margin-bottom: 5px;
}

.scrollbar {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .cards-container {
    height: 360px;
  }
}

@media only screen and (max-width: 500px) {
  .card-item {
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .txt-container {
    text-align: center;
    width: 100%;
    padding-right: 0;
  }

  .txt-container > p {
    padding-left: 0;
    margin: 10px;
  }

  .txt-container > h1 {
    padding-left: 0;
  }
}
